<template>
    
    <div class="page">

        <div class="hero w-full pt-2 bg-red-400 h-52 rounded-b-2xl md:bg-transparent">
            <div class="profile px-3 mb-3 md:px-0 text-white md:text-gray-900" v-if="dashboard != null">
                <h2 class="mb-1">{{ dashboard.greeting }},</h2>
                <div class="flex items-center" v-if="userLogin != null">
                    <router-link to="/profil/home" class="w-12 flex-none">
                        <img :src="userLogin.user.avatar" class="h-12 w-12 rounded-full"/>
                    </router-link>
                    <router-link to="/profil/home" class="flex-wrap ml-3 flex-grow overflow-ellipsis overflow-hidden">
                        <h2 class="text-lg font-semibold truncate ">{{ userLogin.user.nama_lengkap }}</h2>
                        <h4 class="text-xs truncate">{{ userLogin.user.email }}</h4>
                    </router-link>
                    <!-- <router-link to="/tagihan" class="block relative md:hidden bg-gray-100 p-1 mr-2 rounded-full text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-200 focus:ring-white">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                        </svg>
                    </router-link>
                    <router-link to="/pemberitahuan" class="block relative md:hidden bg-gray-100 p-1 rounded-full text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-200 focus:ring-white">
                        <div class="h-3 w-3 rounded-full bg-red-500 absolute -top-0.5 -right-0.5" v-if="notifCount > 0"></div>
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                        </svg>
                    </router-link> -->
                </div>
                <div class="flex" v-else>
                    <img src="../assets/images/default.png" class="h-12 w-12 rounded-full"/>
                    <div class="flex-wrap ml-3">
                        <h2 class="text-lg font-semibold">Guest</h2>
                        <h4 class="text-xs">
                            <router-link to="/login" class="mr-2">Login</router-link>
                            <!-- <router-link to="/register" class="mr-2">Register</router-link> -->
                        </h4>
                    </div>
                </div>
            </div>
        </div>

        <div class="px-3 md:px-0 -mt-28">
            <div class="banner rounded-3xl overflow-hidden bg-white shadow-md">
                <agile :nav-buttons="false" :autoplay-speed="5000" :speed="2500" fade="fade" pause-on-hover="pause-on-hover" pause-on-dots-hover="pause-on-dots-hover" autoplay="autoplay" v-if="banner.length > 0" v-lazy-container="{ selector: 'img'}">
                    <img class="slide" :data-src="slide.path" v-for="(slide) in banner" :key="slide.path"/>
                </agile>
            </div>
        </div>


        <div class="pt-4 pb-2 px-3 md:px-0" v-if="dashboard != null && typeof dashboard.juara != 'undefined' && dashboard.juara.length > 0">
            <div class="bg-gray-100 rounded-lg p-3 py-6">
                <div class="text-center text-lg font-semibold">Juara Minggu Ini</div>
                <p class="text-center text-gray-500">{{ dashboard.minggu_mulai }} sampai {{ dashboard.minggu_selesai }}</p>

                <div class="grid grid-cols-3 mt-2 mb-2 gap-3">
                    <div>
                        <div class="bg-white shadow-md rounded-lg p-2 text-center relative" v-if="dashboard.juara[1] != null" >
                            <div class="h-10 w-10 rounded-full flex items-center bg-yellow-400 text-xl text-white font-bold absolute ">
                                <div class="text-center mx-auto">2</div>
                            </div>
                            <div class="mb-1 mt-1">
                                <img :src="dashboard.juara[1].avatar" class="h-24 w-24 rounded-full mx-auto"/>
                            </div>
                            <div class="font-semibold">{{ dashboard.juara[1].nama_lengkap }}</div>
                            <div class="font-semibold text-yellow-500">{{ dashboard.juara[1].total_skor }}</div>
                        </div>
                    </div>
                    <div>
                        <div class="bg-white shadow-md rounded-lg p-2 text-center relative">
                            <div class="h-12 w-12 rounded-full flex items-center bg-yellow-500 text-xl text-white font-bold absolute">
                                <div class="text-center mx-auto">1</div>
                            </div>
                            <div class="mb-1 mt-1">
                                <img :src="dashboard.juara[0].avatar" class="h-24 w-24 rounded-full mx-auto"/>
                            </div>
                            <div class="font-semibold">{{ dashboard.juara[0].nama_lengkap }}</div>
                            <div class="font-semibold text-yellow-500">{{ dashboard.juara[0].total_skor }}</div>
                        </div>
                    </div>
                    <div>
                        <div class="bg-white shadow-md rounded-lg p-2 text-center relative" v-if="dashboard.juara[2] != null" >
                            <div class="h-10 w-10 rounded-full flex items-center bg-yellow-300 text-xl text-white font-bold absolute">
                                <div class="text-center mx-auto">3</div>
                            </div>
                            <div class="mb-1 mt-1">
                                <img :src="dashboard.juara[2].avatar" class="h-24 w-24 rounded-full mx-auto"/>
                            </div>
                            <div class="font-semibold">{{ dashboard.juara[2].nama_lengkap }}</div>
                            <div class="font-semibold text-yellow-500">{{ dashboard.juara[2].total_skor }}</div>
                        </div>
                    </div>

                </div>

            </div>
        </div>


        <div class="menu pt-4 pb-2 px-3 md:px-0">
            <p class="mt-3 text-xl leading-8 font-semibold tracking-tight text-gray-900">
                Menu Utama
            </p>

            <div class="grid grid-cols-2 lg:grid-cols-4 sm:grid-cols-4 gap-2 md:gap-10 sm:gap-2 my-3 text-gray-600 hover:text-gray-800 transition-all">
                <router-link :to="{
                    name  : 'dashboardKategori',
                    params: { singkatan_kategori: data.singkatan.toLowerCase() }
                }" class="my-3 items-center text-gray-800 hover:text-gray-900 transition-all py-4 px-1 rounded-lg flex flex-col bg-yellow-100" 
                        v-for="(data, index) in kategori"
                        :key="index">
                    
                    <div class="w-1/3 mb-3">
                        <img :src="data.thumbnail" class="rounded-md" />
                    </div>
                    <h3 class="block text-center font-semibold">{{ data.judul }}</h3>
                </router-link>
            </div>

        </div>


    </div>

</template>
<script>
    import { VueAgile } from 'vue-agile'
    import { errorHandler, showErrorNotif, copyToClipboard } from '../helpers/Tools';
    export default {
        name: 'Home',
        components : {
            agile: VueAgile ,
        },
        data(){
            return {
                kategori : [],
                banner   : [],
                dashboard: null,
                list: [0, 1, 2, 3, 4]
            }
        },
        computed: {
			userLogin () {
				return this.$store.getters.userLogin
            },
            notifCount () {
				return this.$store.getters.getNonReadNotification
            }
        },
        mounted() {
            this.getDasboardInfo();
            this.getBanner();
            this.getKategori();
            // this.getPromo();
		},
		methods : {
           
            async getBanner() {
                try {
                    let res = await this.$store.dispatch("getBanner")
                    let result = res.data
                    if(result.status == 'success') {
                        this.banner = result.data
                    } else {
                        showErrorNotif(this, result.message);
                    }
                } catch (error) {
                    errorHandler(this, error);
                }
            },
            async getKategori() {
                try {
                    let res = await this.$store.dispatch("getKategori")
                    let result = res.data
                    if(result.status == 'success') {
                        this.kategori = result.data
                    } else {
                        showErrorNotif(this, result.message);
                    }
                } catch (error) {
                    errorHandler(this, error);
                }
            },
            async getDasboardInfo() {
                try {
                    let res = await this.$store.dispatch("getDashboardInfo")
                    let result = res.data
                    if(result.status == 'success') {
                        this.dashboard = result.data
                        if(this.dashboard.user != null) {
                            this.nonReadNotifCount();
                        }
                    } else {
                        showErrorNotif(this, result.message);
                    }
                } catch (error) {
                    errorHandler(this, error);
                }
            },

            async nonReadNotifCount() {
                    let res = await this.$store.dispatch("getNonReadNotifCount")
                    let result = res.data
                    if(result.status == 'success') {
						this.$store.dispatch('actionOnNonReadNotif', result.data)
                    }
            },
        }
    }
</script>

<style>

.agile__nav-button {
  background         : transparent;
  border             : none;
  color              : #fff;
  cursor             : pointer;
  font-size          : 24px;
  height             : 100%;
  position           : absolute;
  top                : 0;
  transition-duration: 0.3s;
  width              : 80px;
}
.agile__nav-button:hover {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}
.agile__nav-button--prev {
  left: 0;
}
.agile__nav-button--next {
  right: 0;
}
.agile__dots {
  bottom: 10px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}
.agile__dot {
  margin: 0 2px;
}
.agile__dot button {
  background-color   : transparent;
  border             : 1px solid #fff;
  border-radius      : 50%;
  cursor             : pointer;
  display            : block;
  height             : 8px;
  font-size          : 0;
  line-height        : 0;
  margin             : 0;
  padding            : 0;
  transition-duration: 0.3s;
  width              : 8px;
}
.agile__dot--current button, .agile__dot:hover button {
  background-color: #fff;
}

.banner {
    height: 250px;
}
.slide {
    display      : block;
    height       : 250px;
    -o-object-fit: cover;
    object-fit   : cover;
    width        : 100%;
}

@media only screen and (max-width: 600px) {
    .banner {
        height: 180px;
    }
    .slide {
        display      : block;
        height       : 180px;
        -o-object-fit: cover;
        object-fit   : cover;
        width        : 100%;
    }
}

.voucher:before {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    background: rgba(249, 250, 251, var(--tw-bg-opacity));
    border-radius: 100%;
    left: -11px;
    top: 45%;
}
.voucher:after {
    content: '';
    position: absolute;
    width: 30px;
    height: 30px;
    background: rgba(249, 250, 251, var(--tw-bg-opacity));
    border-radius: 100%;
    right: -11px;
    top: 45%;
}
</style>